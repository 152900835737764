var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"birBagisYap padding-wrapper"},[_c('div',{staticClass:"d-flex flex-column flex-md-row align-items-end"},[_vm._m(0),_c('div',{staticClass:"bottom-information text-left text-md-right"},[_c('Toggle',{attrs:{"labels":_vm.toggleLabels,"bgColor":"orange"},on:{"toggle-checkbox":function($event){return _vm.checkboxValue($event)}}})],1)]),_c('div',{staticClass:"d-flex flex-wrap flex-lg-nowrap flex-column flex-lg-row my-4"},[_c('div',{staticClass:"d-grid grid-t-columns-6 flex-3 mr-0 mr-lg-3 mb-3 mb-lg-0"},[_vm._l((_vm.donationData),function(item){return [(
            item.type === 'onetime' && _vm.formValue.donation_status !== 'duzenli'
          )?_c('div',{key:item.id,staticClass:"bagis-card bagis-card-static",attrs:{"id":item.id},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.selectDonation($event, 'bagis', 'auto', item.id, item.price, 1)}}},[_c('img',{staticClass:"check-icon",attrs:{"src":"/svg/check.svg"}}),_c('div',{staticClass:"price"},[_vm._v(_vm._s(item.full_price))]),_c('div',{staticClass:"price-card-desc"},[_c('span',[_vm._v(_vm._s(item.description))])])]):_vm._e(),(
            item.type !== 'onetime' && _vm.formValue.donation_status === 'duzenli'
          )?_c('div',{key:item.id,staticClass:"bagis-card bagis-card-static",attrs:{"id":item.id},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.selectDonation($event, 'bagis', 'auto', item.id, item.price, 1)}}},[_c('img',{staticClass:"check-icon",attrs:{"src":"/svg/check.svg"}}),_c('div',{staticClass:"price"},[_vm._v(_vm._s(item.full_price))]),_c('div',{staticClass:"price-card-desc"},[_c('span',[_vm._v(_vm._s(item.description))])])]):_vm._e()]}),_c('div',{staticClass:"bagis-card text-donation",on:{"click":function($event){return _vm.selectDonation($event, 'bagis', 'manuel', 0, 0, 1)}}},[_c('img',{staticClass:"check-icon",attrs:{"src":"/svg/check.svg"}}),_c('div',{staticClass:"price-card-desc"},[_c('the-mask',{staticClass:"price",attrs:{"tokens":_vm.minVal,"mask":"XYYY","placeholder":"0 TL","type":"tel"},on:{"input":function($event){_vm.donationValue >= 25
                ? (_vm.bagisCardIsSelected = true)
                : (_vm.bagisCardIsSelected = false)}},model:{value:(_vm.donationValue),callback:function ($$v) {_vm.donationValue=$$v},expression:"donationValue"}}),_c('div',{staticClass:"h-100 d-flex flex-column justify-content-center"},[_c('p',{staticClass:"d-none d-md-block"},[(_vm.formValue.donation_status === 'duzenli')?_c('span',[_vm._v("Düzenli"),_c('br')]):_vm._e(),_vm._v(" Bağış Miktarını "),_c('br'),_vm._v(" Kendin Belirle ")]),_c('p',{staticClass:"d-block d-md-none mt-3"},[(_vm.formValue.donation_status === 'duzenli')?_c('b',[_vm._v("Düzenli ")]):_vm._e(),_vm._v(" Bağış Miktarını Kendin Belirle ")]),_c('span',{staticClass:"d-none d-md-block"},[_vm._v("Bağış yapmak istediğin tutarı kendin belirle.")]),(_vm.donationValue >= 1 && _vm.donationValue < 25)?_c('span',{staticClass:"minValWarn"},[_vm._v("Minimum bağış miktarı 25TL'dir.")]):_vm._e()])],1)]),_c('div',{staticClass:"bagis-card text-donation",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.selectDonation(
            $event,
            'bilimseti',
            'auto',
            0,
            2000,
            _vm.formValue.amount
          )}}},[_c('img',{staticClass:"check-icon",attrs:{"src":"/svg/check.svg"}}),_c('div',{staticClass:"price-card-desc"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.formValue.amount),expression:"formValue.amount"}],staticClass:"custom-selectbo selectpicker text-donation",attrs:{"id":"selectpickerr"},on:{"input":function($event){_vm.formValue.amount >= 1
                ? (_vm.bagisCardIsSelected = true)
                : (_vm.bagisCardIsSelected = false)},"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.formValue, "amount", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){_vm.selectDonation(
                $event,
                'bilimseti',
                'auto',
                0,
                3700,
                _vm.formValue.amount
              );
              _vm.bagisCardIsSelected = true;}]}},[_c('option',{attrs:{"value":"0","disabled":"","selected":""}},[_vm._v("Seçiniz")]),_c('option',{attrs:{"value":"1"}},[_vm._v("1 Bilim Seti")]),_c('option',{attrs:{"value":"2"}},[_vm._v("2 Bilim Seti")]),_c('option',{attrs:{"value":"3"}},[_vm._v("3 Bilim Seti")]),_c('option',{attrs:{"value":"4"}},[_vm._v("4 Bilim Seti")]),_c('option',{attrs:{"value":"5"}},[_vm._v("5 Bilim Seti")]),_c('option',{attrs:{"value":"6"}},[_vm._v("6 Bilim Seti")]),_c('option',{attrs:{"value":"7"}},[_vm._v("7 Bilim Seti")]),_c('option',{attrs:{"value":"8"}},[_vm._v("8 Bilim Seti")]),_c('option',{attrs:{"value":"9"}},[_vm._v("9 Bilim Seti")]),_c('option',{attrs:{"value":"10"}},[_vm._v("10 Bilim Seti")])]),_c('div',{staticClass:"h-100 d-flex flex-column justify-content-center"},[_c('p',{staticClass:"d-none d-md-block"},[(_vm.formValue.donation_status === 'duzenli')?_c('span',[_vm._v("Düzenli "),_c('br')]):_vm._e(),_vm._v(" Bilim Seti Gönder ")]),_c('p',{staticClass:"d-block d-md-none mt-3"},[(_vm.formValue.donation_status === 'duzenli')?_c('b',[_vm._v("Düzenli")]):_vm._e(),_vm._v(" Bilim Seti Gönder ")]),_vm._m(1)])])]),_c('div',{staticClass:"bagis-card text-donation",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.selectDonation(
            $event,
            'bilimseti',
            'auto',
            0,
            10000,
            1,
            _vm.formValue.city
          )}}},[_c('img',{staticClass:"check-icon",attrs:{"src":"/svg/check.svg"}}),_c('div',{staticClass:"price-card-desc"},[_vm._m(2),_vm._m(3),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.formValue.city),expression:"formValue.city"}],staticClass:"custom-selectbo selectpicker text-donation",attrs:{"id":"selectpicker"},on:{"input":function($event){_vm.formValue.city >= 1 ? (_vm.bagisCardIsSelected = true) : (_vm.bagisCardIsSelected = false)},"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.formValue, "city", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){_vm.selectDonation( $event, 'bilimseti', 'auto', 0, 18500, 1, _vm.formValue.city);
              _vm.bagisCardIsSelected = true;}]}},[_c('option',{attrs:{"value":"0","disabled":"","selected":""}},[_vm._v("İl Seç")]),_vm._l((_vm.cities),function(city,index){return _c('option',{key:index,domProps:{"value":city.id}},[_vm._v(" "+_vm._s(city.title)+" ")])})],2)])])],2)]),_c('div',{staticClass:"\n      d-flex\n      align-items-center\n      flex-column flex-md-row\n      justify-content-between\n      bottom-info\n    "},[_vm._m(4),_c('ButtonRounded',{staticClass:"floating-button",attrs:{"Text":"Devam Et","isRouting":false,"disabled":!_vm.bagisCardIsSelected},nativeOn:{"click":function($event){return _vm.formValidation.apply(null, arguments)}}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"heading"},[_c('h3',[_vm._v("Sen de Bağış Yap")]),_c('p',{staticClass:"mt-4"},[_vm._v("Bağışınızı düzenli veya tek seferlik yapabilirsiniz.")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"d-none d-md-block"},[_c('span',[_vm._v("Bir Bilim Setinin bedeli "),_c('strong',[_vm._v("3.700TL")]),_vm._v("'dir, her setten "),_c('strong',[_vm._v("80 çocuk")]),_vm._v(" faydalanır.")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h2',{staticClass:"d-flex justify-content-center align-items-center flex-column"},[_vm._v(" 18.500TL"),_c('span',{staticClass:"ml-3 mb-1 d-md-none d-block"},[_vm._v("Bir Okul Sahiplen")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-100 d-flex flex-column justify-content-center"},[_c('p',{staticClass:"d-none d-md-block"},[_vm._v("Bir Okul Sahiplen")]),_c('span',{staticClass:"d-none d-md-block"},[_vm._v("400 çocuğu Bilim Seti ile buluştur.")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"svg-icon icons-information"}),_c('p',[_c('b',[_vm._v("EFT veya Havale ile Bağış Yap! ")]),_vm._v(" "),_c('br'),_vm._v(" Yapı ve Kredi Bankası - Üsküdar Şubesi (022) "),_c('br'),_vm._v(" Hesap No: 87334821 Unvan: Hayal Ortakları Derneği "),_c('br'),_vm._v(" IBAN: TR81 0006 7010 0000 0087 3348 21")])])}]

export { render, staticRenderFns }
<template>
<div class="d-inline">
  <!-- <div class="custom-toggle d-none d-md-inline-flex align-items-center justify-content-center" :class="bgColor">
      <span :class="{ 'active' : !checked }" >{{ labels[0] }}</span>
      <input type="checkbox" class="switch" 
        v-model="checked"
        >
      <span :class="{ 'active' : checked }" >{{ labels[1] }}</span>
  </div> -->
  <div class="custom-switch d-flex">
    <label class="btn-color-mode-switch">
      <input type="checkbox" name="color_mode" value="1" v-model="checked">
      <label for="color_mode" :data-on="labels[0]" :data-off="labels[1]" class="btn-color-mode-switch-inner"></label>
    </label>
  </div>
  
</div>
</template>
<script>
export default {
  name: "toggle",
  props:{
    labels: Array,
    bgColor: String,
    type: String,
  },
  data(){
    return{
      checked: false,
    }
  },
  watch:{
    checked(){
      this.$emit('toggle-checkbox', this.checked);
    }
  },
}
</script>
<style lang="scss">
.custom-toggle{
  &.orange{
    input[type="checkbox"].switch{
      background: var(--orange);
    }
    span{
      color: var(--gray);
      &.active{
        color: var(--orange);
      }
    } 
  }
  input[type="checkbox"].switch{
    appearance: none;
    width: 3.5rem;
    height: 2rem;
    background: var(--black);
    margin: 0 0.5rem;
    border-radius: 3rem;
    position: relative;
    cursor: pointer;
    outline: none;
    transition: all .2s ease-out;
    font-size: 1rem;
    &:checked{	  
      &:after{
        left: calc(100% - 2rem);
      }
    }
    &:after{
      position: absolute;
      content: "";
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background: #fff;
      box-shadow: 0 0 .25em rgba(0,0,0,.3);
      transform: scale(.7);
      left: 0;
      transition: all .2s ease-out;
    }
  }

  @media (max-width: 567px) {
    background: var(--purpleDark);
    border-radius: 8px;
    padding: 1rem;
  }
}

.custom-switch{
  max-width: 500px;
  margin: 0.5rem 0;
  padding-left: 0 !important;
  padding-right: 0;
  margin-left: auto;
  .btn-color-mode-switch{
    display: inline-block;
    margin: 0px;
    position: relative;
    width: 100%;
  
    & > label.btn-color-mode-switch-inner{
      margin: 0px;
      width: 100%;
      height: 60px;
      background: var(--purpleDark);
      border-radius: 8px;
      overflow: hidden;
      position: relative;
      transition: all 0.3s ease;
      display: block;
      font-size: 1.2rem;
      
      &:before{
        content: attr(data-on);
        position: absolute;
        top: 7px;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100% - 14px);
        width: calc(50% - 7px);
        opacity: .3;
      }
  
      &:after{
        content: attr(data-off);
        width: calc(50% - 7px);
        height: calc(100% - 14px);
        background: var(--orange);
        border-radius: 5px;
        position: absolute;
        left: 7px;
        top: 7px;
        transition: all 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  
    input[type="checkbox"]{
      cursor: pointer;
      width: 100%;
      height: 100%;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      margin: 0px;
      
      &:checked + label.btn-color-mode-switch-inner{
        &:after{
          content: attr(data-on);
          left: 50%;
        }
  
        &:before{
          content: attr(data-off);
          right: auto;
        }
      }
    }
  }
}
</style>

<template>
  <header class="d-flex align-items-center justify-content-between">
    <router-link to="/" class="logo-wrapper">
      <img class="non-sticky" src="/svg/bs-logo-white.svg" alt="Bilim Seferberliği">
      <img class="sticky" src="/svg/bs-logo.svg" alt="Bilim Seferberliği">
    </router-link>
    
    <ButtonRounded class="float-right" 
      Text="Bağış Yap"
      btnSize="middle" 
      :isRouting="false"
      @click.native="scrollToView"
      />
  </header>
</template>

<script>
import ButtonRounded from "@/components/ButtonRounded";

export default {
  name: "Header",
  components: {
    ButtonRounded
  },
  mounted(){
    this.getFooterSticky();
  },
  methods:{
    showModal(){
      this.$parent.showModal();
    },
    scrollToView(){
      let scrollPosition = window.innerWidth > 768 ? 'center' : 'start';      
      var elem = document.querySelector(".howCanIdonate");
      elem.scrollIntoView({ behavior: "smooth", block: scrollPosition });
    },
    getFooterSticky(){
      $(window).on("scroll", function() {
          if($(window).scrollTop() > 100) {
            $("header").addClass("sticky");
          } else {
            $("header").removeClass("sticky");
          }
      });
    }
  }
}
</script>

<style lang="scss">
header{
  position: relative;
  width: 100vw;
  top: 0;
  padding: 1rem 2rem;
  margin-bottom: -90px;
  z-index: 2;
  transition: var(--fast);
  &.sticky{
    position: fixed;
    background: #fff;
    margin-bottom: 0;
    .non-sticky{
      display: none;
    }
    .sticky{
      display: inline-block;
    }
  }
  .non-sticky{
    display: inline-block;
  }
  .sticky{
    display: none;
  }

  .logo-wrapper{
    img{
      width: 170px;
      // height: 46px;
      aspect-ratio: 170 / 46;
    }
  }

  @media(max-width: 768px){
    padding: 1rem;
    .sticky, .non-sticky{
      width: 130px;
    }

    .logo-wrapper{
      img{
        width: 155px;
      }
    }
  }
}
</style>
<template>
  <div>
    <loading :active.sync="isLoading" 
    background-color="#332abb"
    :opacity= 0.99
    color="#fff"
    :is-full-page="fullPage"></loading>

    <Header ref="showModal" />
    <modal ref="modalName"></modal>
    <modalVideo ref="modalVideo"></modalVideo>
    <modalGallery ref="modalGallery"></modalGallery>

    <section class="main-banner text-center">
      <div class="container">
        <div class="main-banner-heading gs_reveal">
          <h1>20 Bin<span class="white"> Çocuğu</span> <br>
            <span class="white">Bilimle Buluştur.</span>
          </h1>
          <p>İmkanları sınırlı, hayalleri sınırsız çocuklarımıza <br> 
          Bilim Seti ulaştırılması için destek ol!</p>
          <!-- <p>23 Nisan'da, <span>okullara dönen</span> 40 bin <span>çocuğu bilimle buluşturalım.</span></p> -->
        </div>

        <div class="progress-rounded" v-if="homeData">
          <div class="barOverflow">
            <div class="bar"></div>
            <div class="barBorder"></div>
            <!-- <p><span class="counter">{{ homeData.percentage }}</span>%</p> -->
            <p><span class="counter">24</span>%</p>
          </div>
          <div class="img-wrapper">
            <img src="/img/banner-cocuk2.png">
            <div class="play-icon" @click="showModalVideo">
              <span class="svg-icon icons-play" ></span>
              <span>Videoyu İzle</span>
            </div>
          </div>
        </div>

        <div class="donation-info d-none d-lg-flex justify-content-between">
          <div class="text-right">
            <div class="numbers">
              <p>Yapılan Bağış</p>
              <h3><span class="countMe" :data-count="homeData.success_donation_price"></span> TL</h3>
              <h4><b class="countMe" :data-count="homeData.child_count"></b> <span>Çocuk</span></h4>
            </div>
          </div>

          <div class="text-left">
            <p>Hedeflenen Bağış</p>
            <h3>{{ homeData.donation_plan }} TL</h3>
            <h4>{{ homeData.donation_plan_child }}<span> Çocuk</span></h4>
          </div>
        </div>

      </div>

      <div class="donation-info d-flex d-lg-none justify-content-between">
        <div class="text-left">
          <p>Yapılan Bağış</p>
          <h3><span class="countMe" :data-count="homeData.success_donation_price" ></span> TL</h3>
          <h4><b class="countMe" :data-count="homeData.child_count" ></b> <span>Çocuk</span></h4>
        </div>

        <div class="text-right">
          <p>Hedeflenen Bağış</p>
          <h3>{{ homeData.donation_plan }} TL</h3>
          <h4>{{ homeData.donation_plan_child }} <span>Çocuk</span></h4>
        </div>
      </div>
    </section>

    <div class="main-continer">
      <section class="container bilim-seti-info">
        <div class="d-flex flex-column flex-lg-row">
          <div class="gs_reveal gs_reveal_fromLeft">
            <h1>Bilim Seti'nin <br>
            içerisinde ne var?</h1>

            <div class="bilim-seti-img d-block d-lg-none gs_reveal gs_reveal_fromRight">
              <img src="/svg/bilim-seti.svg">
            </div>

            <p>Ders sırasında müfredata uygun şekilde <br>
            kullanılmak üzere tasarlanmış bilim setleri</p>

            <ul>
              <li>30 adet Twin modülü, powerbank ve ek malzemeler.</li>
              <li>Mıknatısla birleşebilen, eğlenceli ve LEGO ile uyumlu modüller.</li>
              <li>Kendin yap deneyler içeren müfredat.</li>
              <li>Arduino uyumlu kodlama modülü.</li>
              <li>Mobil uygulama, web platformu ve akıllı tahta üzerinden blok ve satır kodlama imkanı.</li>
            </ul>

            <ButtonRounded 
              Link="/test" 
              Text="Bilim Setini İncele" 
              color="blue" 
              btnSize="middle" 
              :isRouting="false"
              @click.native="showModalGallery"
            />
          </div>

          <div class="bilim-seti-img d-none d-lg-block gs_reveal gs_reveal_fromRight">
            <img src="/svg/bilim-seti.svg">
          </div>

        </div>
        
        <p class="footnote text-center"><span>*50 TL</span> bağışınızla bir öğrencinin bilim setiyle buluşmasına destek olabilirsiniz. <br>
        <span>* 18.500 TL</span> bağışınızla bir okulun tüm öğrencilerinin bilim setiyle buluşmasına destek olabilirsiniz.</p>

      </section>

      <section class="howCanIdonate d-flex">
        <BirBagisYap :donationData="donationData" :cities="allCities" />
      </section>

      <!-- <section class="howCanIdonate text-center">
        <div class="container">
          <div class="gs_reveal">
            <h1>Nasıl Bağış Yapabilirim?</h1>
          </div>

          <div class="d-flex flex-column flex-lg-row">
            <div class="donate-box gs_reveal gs_reveal_fromLeft">
              <img src="/svg/eft-havale.svg">
              <h3>
                EFT veya Havale ile <br>
                Bağış Yap!
              </h3>

              <p>Yapı ve Kredi Bankası - Üsküdar Şubesi (022)<br>
              Hesap No: 87334821 Unvan: Hayal Ortakları Derneği<br>
              IBAN: TR81 0006 7010 0000 0087 3348 21</p>
            </div>
            
            <div class="donate-box gs_reveal gs_reveal_fromRight">
              <img src="/svg/online-bagis.svg">
              <h3>
                Kredi Kartıyla<br>
                Bağış Yap!
              </h3>

              <p>Kredi kartıyla yapacağınız bağış ile imkanları kısıtlı daha çok çocuğu bilimle buluşturabilirsiniz. <br>
              <strong @click="showModal()">Hemen bağış yapmak için tıklayın.</strong></p>
            </div>

          </div>

          <div class="d-flex align-items-center flex-column flex-md-row justify-content-center mt-4">
            <img src="/svg/bubble.svg">
            <p class="footnote text-center">SMS bağışı için<span>“Bilim”</span> yazıp <span>6290</span>’a
            gönderebilirsiniz. *1 SMS = 10 TL bağış değerindedir.</p>
          </div>

        </div>
      </section> -->

      <section class="gray-section">
        <div class="container d-flex flex-column-reverse flex-lg-row align-items-center">

          <div class="parallax-img-wrapper d-none d-md-flex align-items-center justify-content-center">
            <img class="prl-0" src="/img/parallax/hands.svg">
            <img class="prl-1" src="/img/parallax/1.png">
            <img class="prl-2" src="/img/parallax/2.png">
            <img class="prl-3" src="/img/parallax/3.png">
          </div>

          <!-- <img src="/img/bs-gorsel.png" class="d-none d-md-block gs_reveal gs_reveal_fromLeft"> -->

          <div class="text-center text-md-right ml-0 ml-md-5 gs_reveal gs_reveal_fromRight">
            <h1 class="mb-3">527.200 Çocuk<br>
            Bilimle Buluştu</h1>
            
            <img src="/img/untitled-1.png" class="d-block d-md-none gs_reveal gs_reveal_fromLeft">
            
            <p>
              Milli Eğitim Bakanlığı’nın desteğiyle hayata geçen Bilim Seferberliği projesinde bugüne kadar 527.200 çocuk bilimle buluştu. Ancak ülkemizde henüz bilimle buluşmayı bekleyen on binlerce imkanları sınırlı, hayalleri sınırsız çocuk var.
            </p>

            <div class="logo-wraper">
              <a href="https://meb.gov.tr/" target="_blank"><img src="/svg/meb-logo.svg"></a>
              <a href="https://www.yga.org.tr/" target="_blank"><img src="/img/yga-logo.png"></a>
            </div>
          </div>
        </div>
      </section>

      <section class="neden-bilim-seferberligi">
        <div class="container d-flex align-items-center text-center text-md-left">
          <div class="gs_reveal gs_reveal_fromLeft">
            <h1>Neden Bilim Seferberliği?</h1>

            <img src="/svg/neden-bilim-seferberligi.svg" class="d-block d-md-none gs_reveal gs_reveal_fromRight">

            <p>
              Dünyanın sorunlarına, bilim sevgisiyle yetişen nesiller çözüm bulacak. Bugünün gençleri yarının köklü çözümlerini üretecek. Bu gençler Anadolu’dan çıkacak, dünyaya örnek olacak.
            </p>

            <p><span>Bu nedenle, ihtiyaç sahibi köy okullarına bilim ve teknolojiyi eğlenceli bir şekilde öğrenebilecekleri ve kendi inovasyonlarını geliştirebilecekleri bilim setleri gönderiyoruz.
              <br>
            Gelin, siz de bağış yapın. Geleceği bilimle dönüştürelim. 
            </span></p>
          </div>

          <img src="/svg/neden-bilim-seferberligi.svg" class="d-none d-md-block gs_reveal gs_reveal_fromRight">
        </div>
      </section>

      <FooterLanding />

    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

import Header from "@/components/Header"
import ButtonRounded from "@/components/ButtonRounded"
import FooterLanding from "@/components/FooterLanding"
import Modal from "@/components/Modal"
import ModalVideo from "@/components/ModalVideo"
import ModalGallery from "@/components/ModalGallery"

import BirBagisYap from "@/components/BirBagisYap";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { ScrollToPlugin } from "gsap/ScrollToPlugin";
// gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);



export default {
  name: "home",
  components: {
    Header, ButtonRounded, FooterLanding, Modal, ModalVideo, ModalGallery, BirBagisYap,
    Loading
  },
  data(){
    return{
      homeData: '',
      isLoading: false,
      fullPage: true,
      donationData: {},
      allCities:{},
    }
  },
  created(){
    this.doAjax();
  },
  mounted(){
    this.getHomeData();
    // this.floatingParallax();
    this.getDonationList();
    this.toggleDonateButton();
    // this.getCities();
  },
  methods: {
    doAjax() {
        this.isLoading = true
        setTimeout(() => {
          this.isLoading = false
        },1500)
    },
    onCancel() {
      console.log('User cancelled the loader.')
    },
    showModal(){
      this.$refs.modalName.openModal();
      this.$refs.modalName.getSwiper();
    },
    showModalVideo(){
      this.$refs.modalVideo.openModal();
    },
    showModalGallery(){
      this.$refs.modalGallery.openModal();
    },
    progressBar(data){
      let percentage = data.percentage;
      let $percentage = $("span.counter");
      
      let barContainer = $(".progress-rounded");
      var $bar = barContainer.find(".bar");

      $({p:0}).animate({p:percentage}, {
        duration: 3000,
        easing: "swing",
        step: function(p) {
          $bar.css({
            transform: "rotate("+ (45+(p*1.8)) +"deg)",
          });
          $percentage.text(p|0);
        }
      });

      $('.countMe').each(function() {
        var $this = $(this);
        var countTo = $this.attr('data-count');

        $({
          countNum: $this.text()
        }).animate({
            countNum: countTo
          },

          {
            duration: 2700,
            easing: 'linear',
            step: function() {
              $this.text(commaSeparateNumber(Math.floor(this.countNum)));
            },
            complete: function() {
              $this.text(commaSeparateNumber(this.countNum));
              //alert('finished');
            }
          }
        );

      });

      function commaSeparateNumber(val) {
        while (/(\d+)(\d{3})/.test(val.toString())) {
          val = val.toString().replace(/(\d+)(\d{3})/, '$1' + '.' + '$2');
        }
        return val;
      }

    },
    getHomeData(){
      this.$api.homeData().then(response =>{
        // console.log(response.data);
        if(response){
          this.homeData = response.data;
          setTimeout(() =>{
            this.progressBar(response.data);
          },100)
        }
      });
    },
    getCities(){
        this.$api.getAllCities().then(response =>{
          this.allCities = response;
        });
    },
    getDonationList(){
      this.$api.donationList().then(response =>{
        this.donationData = response;
        this.isLoading = false;
      });
    },
    floatingParallax(){
      var timeout;
    $('.gray-section .container').mousemove(function(e){
        if(timeout) clearTimeout(timeout);
        setTimeout(callParallax.bind(null, e), 200);
      });

      function callParallax(e){
        parallaxIt(e, '.prl-0', 10);
        parallaxIt(e, '.prl-1', -5);
        parallaxIt(e, '.prl-2', 5);
        parallaxIt(e, '.prl-3', 5);
      }

      function parallaxIt(e, target, movement){
        var $this = $('.gray-section .container');
        var relX = e.pageX - $this.offset().left;
        var relY = e.pageY - $this.offset().top;
        
        TweenMax.to(target, 1, {
          x: (relX - $this.width()/2) / $this.width() * movement,
          y: (relY - $this.height()/2) / $this.height() * movement,
          ease: Power2.easeOut
        })

      }
    },

    toggleDonateButton(){
      $(".floating-button").each(function() {
        // Content Reveal Animation
        ScrollTrigger.create({
          trigger: ".howCanIdonate",
          start: "top top",
          end: "bottom top",
          
          // markers: true,
          onEnter: () => $(this).addClass('hidden'),
          onLeave: () => $(this).removeClass('hidden'),
          onEnterBack: () => $(this).addClass('hidden'),
          onLeaveBack: () => $(this).removeClass('hidden'),
        });
      });
    }
  }
}
</script>

<style lang="scss">
.play-icon{
  .icons-play{
     width: 90px;
  height: 90px;
  background: var(--orange);
  }
  span{
    color: var(--orange);
  }
}
.main-banner{
  width: 100vw;
  padding: 90px 0 20px 0;
  background-color: var(--purpleLight);
  background-image: url('/svg/pattern.svg'),
                    url('/svg/pattern.svg');
  background-repeat: no-repeat, no-repeat;
  background-position: left center, right center;
  .main-banner-heading{
    padding-bottom: 4rem;
    h1{
      color: var(--green);
      // line-height: 0.8;
      margin-bottom: .5rem;
      span.white{
        color: var(--whiteOld);
      }
      span.small{
        font-size: 2.3rem;
        font-weight: 600;
        display: block;
      }
    }
    p{
      color: var(--whiteOld);
    }
  }

  .donation-info{
    color: #fff;
    margin-top: 20px;
    margin-left: 55px;
    margin-right: 35px;
    .numbers{
      min-width: 160px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    p{
      font-size: .8rem;
    }
    h3{
      font-size: 1.7rem;
      font-weight: 700;
      // color: var(--yellow);
    }
    h4{
      font-size: 1.2rem;
      font-weight: 700;
      // color: var(--orange);
      span{
        font-weight: 300;
        color: #fff;
      }
    }

    .text-right{
      h3, h4{
        color: var(--orange);
      }
    }
  }

  @media (max-width: 1366px) {
    .donation-info{
      margin-left: 50px;
      margin-right: 75px;
    }
  }
  
  @media (max-width: 1024px) {
    .donation-info{
      margin-left: 30px;
      margin-right: 55px;
    }
  }

  @media (max-width: 768px) {
    padding: 110px 0 20px 0;
    .main-banner-heading{
      padding-bottom: 1.5rem;
      h1{
        margin-bottom: .5rem;
        span.small{
          font-size: 1.5rem;
        }
      }
    }
  }

  @media (max-width: 576px){
    padding: 110px 0 0 0;
    .donation-info {
      margin-top: 90px;
      margin-left: 0;
      margin-right: 0;
      h3, h4{
        color: #fff;
      }

      .text-left, .text-right{
        width: 50%;
        padding: 45px 1rem 25px 1rem;
      }
      .text-left{
        background: var(--orange);
      }
      .text-right{
        background: var(--blueOcean);
      }
    }
  }
}

.progress-rounded{
  width: 800px;
  position: relative;
  margin: 0 auto;
  text-align: center;
  .barOverflow{
    position: relative;
    overflow: hidden;
    width: 800px; 
    // height: 410px;
    height: 400px;
    .bar{
      transform: rotate(45deg);
      position: absolute;
      top: 10px; 
      left: 10px;
      width: 780px; 
      height: 780px;
      border-radius: 50%;
      box-sizing: border-box;
      border: 40px solid #fff;    
      border-bottom-color: var(--orange); 
      border-right-color: var(--orange);
      z-index: 1;
    }
    .barBorder{
      position: absolute;
      top: 0;
      left: 0;
      width: 800px;
      height: 800px;
      border-radius: 50%;
      box-sizing: border-box;
      border: 60px solid #fff;
      z-index: 0;
    }
  
    p{
      position: absolute;
      top: 15px;
      left: 50%;
      transform: translateX(-50%);
      color: var(--blueOcean);
      font-size: 1.2rem;
      font-weight: 600;
      z-index: 1;
    }
  }

  .img-wrapper{
    position: absolute;
    width: calc(100% - 140px);
    left: calc(50% + 0px);
    top: 70px;
    transform: translateX(-50%);
    .play-icon{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #fff;
      font-weight: 600;
      cursor: pointer;
    }
  }

  @media (max-width: 1024px){
    width: 600px;

    .barOverflow{
      width: 600px;
      height: 305px;

      .bar{
        width: 590px;
        height: 590px;
        top: 5px;
        left: 5px;
        border: 20px solid #fff;
        border-bottom-color: var(--orange);
        border-right-color: var(--orange);
      }
      .barBorder{
        width: 600px;
        height: 600px;
        border: 30px solid #fff;
      }
      p{
        top: 3px;
      }
    }

    .img-wrapper{
      width: calc(100% - 80px);
      top: 40px;
    }
  }

  @media (max-width: 576px){
    width: 280px;
    .barOverflow{
      width: 280px;
      height: 143px;
      .bar{
        width: 270px;
        height: 270px;
        top: 5px;
        left: 5px;
        border: 20px solid #fff;
        border-bottom-color: var(--orange);
        border-right-color: var(--orange);
      }
      .barBorder{
        width: 280px;
        height: 280px;
        // border: 25px solid #fff;
      }
      p{
        top: 8px;
        font-size: .8rem;
      }
    }
    .img-wrapper{
      width: calc(100% - 70px);
      top: 35px;
      .play-icon{
        img{
          width: 70%;
        }
      }
    }
  }
}

.main-continer{
  padding-top: 15rem;
  color: var(--purpleDark);
  overflow-x: hidden;
  position: relative;

  ul{
    list-style: disc;
    font-size: .8rem;
    margin-left: 1rem;
    margin-bottom: 2rem;
  }

  .footnote{
    font-size: .8rem;
    font-weight: 400 !important;
    span{
      font-weight: 600;
    }
  }

  .bilim-seti-info{
    p{
      font-weight: 600;
      margin: 1rem 0;
    }

    .bilim-seti-img{
      img{
        min-width: 100%;
        margin-top: -7rem;
        margin-left: 0rem;
      }
    }
    
    .footnote{
      margin: 3rem 0;
    }
  }

  @media (max-width: 576px){
    padding-top: 4rem;
    .bilim-seti-info{
      text-align: center;
      .bilim-seti-img img {
        min-width: unset;
        margin-top: 2rem;
        margin-bottom: 1rem;
        margin-left: 0;
        width: 80%;
      }
      ul{
        text-align: left;
      }
      .footnote {
        margin: 1.5rem 0;
        font-size: 0.6rem;
        font-weight: 300;
      }
    }
  }

  .button-rounded.middle{
    width: 100%;
  }

}

.howCanIdonate{
  background: var(--purpleLight);
  color: #fff;
  padding: 4rem 0;
  min-height: 80vh;
  h1{
    margin: 0 0 2rem;
  }
  p{
    &.footnote{
      margin: 0;
      color: var(--letterBlue);
    }
  }
  .donate-box{
    margin: 1rem;
    width: 100%;
    h3{
      margin: 1rem 0;
      font-size: 1.6rem;
    }
    p{
      strong{
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  @media (max-width: 576px){
    padding: 4rem 0 0 0;
    p.footnote{
      margin-top: .5rem;
    }
  }
}

.gray-section{
  background: var(--whiteOld);
  padding: 4rem 0;
  .parallax-img-wrapper{
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 450px;
    img{
      position: absolute;
      max-width: 180px;
      &.prl-0{
        width: 100%;
        max-width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        z-index: 1;
      }
      &.prl-1{ top: 0; left: 0;}
      &.prl-2{ top: 0; right: 0;}
      &.prl-3{ bottom: 0; left: 0;}
    }
  }
  .logo-wraper{
    img{
      margin: 1rem 0 0 1rem;
    }
  }
  @media (max-width: 1024px){
    .parallax-img-wrapper{
      img{
        max-width: 150px;
      }
    }
  }
  @media (max-width: 576px){
    img{
      padding: 1rem;
    }
  }
}

.neden-bilim-seferberligi{
  padding: 6rem 0;
  p{
    margin: 1.5rem 0;
    span{
      font-size: .8rem;
    }
  }
  @media (max-width: 576px){
    padding: 4rem 0 10rem 0;
    img{
      padding: 1rem;
    }
  }
}

@media (max-width: 576px){
.floating-button{
  position: fixed;
  bottom: 1.5rem;
  left: 0;
  width: calc(100% - 4rem) !important;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
  transform: translateY(150%);
  transition: var(--slow);
  visibility: hidden;
  opacity: 0;

  &.hidden{
    visibility: visible;
    opacity: 1;
    transform: translateY(0%);
  }
}
}

</style>
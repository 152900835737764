<template>
  <div class="frame-footer d-flex align-items-center justify-content-between">

    <button @click="prevSlide" type="button" class="back-button d-flex align-items-end">
      <span class="svg-icon icons-back-arrow"></span>
      <p class="d-flex flex-column align-items-start">
        <span>{{backNav[0]}}</span>
        Geri Dön
      </p>
    </button>

    <!-- <button type="button" @click="closeModal">Kapat</button> -->
    <button type="button" @click="closeModal">Kapat</button>

  </div>
</template>

<script>
export default {
  name: "FrameFooter",
  props:{
    backNav: Array
  },
  methods: {
    prevSlide(){
      this.$parent.prevSlide();
    },
    closeModal(){
      this.$parent.destroyCarousel();
      this.$router.push('/')
      // this.$parent.closeModal();
      
    }
  }
};
</script>

<style lang="scss">
.frame-footer {
  position: relative;
  width: 100vw;
  height: 150px;
  background: var(--purpleDark);
  z-index: 1;
  padding: 0 10vw;
  
  button{
    background: transparent;
    color: #fff;
    text-decoration: underline;
    &.back-button{
      text-decoration: none;
      color: var(--green);
      .svg-icon{
        background: var(--green);
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.3rem;
      }
      p{
        font-weight: 500;
        span{
          font-size: .8rem;
          font-weight: 300;
          line-height: 1;
          opacity: .5;
        }
      }
    }
  }

  @media(max-width: 1366px){
    height: 100px;
  }

  @media(max-width: 768px){
    height: 80px;
    background: rgba(24,15,100, .3);
  }
}
</style>

<template>
  <div class="form-success d-flex w-100 h-100 flex-md-row flex-column">
    
    <div class="sides left-side section-wrapper d-md-flex align-items-center justify-content-center">
      <div class="sides-container d-flex flex-column justify-content-around">
        <div>
          <h3>Teşekkürler</h3>
          <h1>{{this.$store.state.userInfo.full_name}}</h1>
        </div>
        <div>
          <div>
            <h2>{{this.$store.state.userInfo.total_price}}</h2>
            <p>
              İmkanları sınırlı, hayalleri sınırsız çocuklarımıza Bilim Seti ulaştırılması için destek oldunuz. <span>Daha çok çocuğun bilimle buluşmasını sağlamak için hemen paylaş.</span>
            </p>
           
            <div class="d-flex justify-content- mt-4 flex-wrap">
              
              <a class="mr-4 button-rounded d-flex align-items-center justify-content-center mr-4 blue fs-09"  
                href="https://twitter.com/intent/tweet?text=23%20Nisan'da,%2040%20Bin%20Çocuğu%20Bilim%20ile%20Buluştur.&url=https://23nisan.bilimseferberligi.org/" 
                data-via="BilimSeferberliği" target="_blank"
              >Twitter'da Paylaş</a>
                    

               <a class="mr-4 button-rounded d-flex align-items-center justify-content-center blue fs-09" 
                href="http://www.facebook.com/sharer/sharer.php?u=https://23nisan.bilimseferberligi.org/&amp;title=23%20Nisan'da,%2040%20Bin%20Çocuğu%20Bilim%20ile%20Buluştur." 
                target="_blank"
              >Facebook’da Paylaş</a>
        
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="sides right-side  w-100 d-flex flex-column align-items-center justify-content-center">
      <img class="success-img" src="/img/anagorsel.jpg">
    </div>
  </div>
</template>
<script>function fbs_click()
 {u=location.href;t=document.title;
 window.open('http://www.facebook.com/sharer.php?u='+encodeURIComponent(u)+'&t='+encodeURIComponent(t),'sharer','toolbar=0,status=0,width=626,height=436');
 return false;}
 </script>
<script>
  !function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0],p=/^http:/.test(d.location)?”http”:”https”;if(!d.getElementById(id))
  {js=d.createElement(s);js.id=id;
  js.src=p+”://platform.twitter.com/widgets.js”;
  fjs.parentNode.insertBefore(js,fjs);}}
  (document, ”script”, ”twitter-wjs”);
</script>
<script>
import ButtonRounded from "@/components/ButtonRounded";

export default {
  name: "FormSuccess",
  components: {
    ButtonRounded
  },
    data() {
      return {
        show:true,
      }
  },

  methods:{
     closeModal() {
        this.$parent.destroyCarousel();
        this.$parent.closeModal();
    },
  },

}
</script>

<style lang="scss">
.form-success{
  .success-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
<template>
  <div class="d-flex w-100 h-100">
    <div class="sides left-side section-wrapper d-none d-md-block position-relative">
      <!-- <h2>Bilgileriniz</h2> -->
      <img class="info-bg" src="/svg/user-info.svg">
    </div>


    <div class="sides right-side section-wrapper d-flex align-items-start align-items-md-center justify-content-center">
      <loading :active.sync="isLoading" 
      background-color="#332abb"
      :opacity= 0.99
      color="#fff"
      :is-full-page="fullPage"></loading>
      <form @submit.prevent="submitForm" autocomplete="off" class="w-100">
        <h2>Bilgileriniz</h2>
        <div class="form-group">
          <label for="userName">İsim Soyisim</label>
          <input 
            v-model="form.userName" 
            v-on:input="removeSpace"
            v-bind:class="{error: $v.form.userName.$error, valid: $v.form.userName.$dirty && !$v.form.userName.$invalid}"
            type="text" class="form-control">
          <p class="form-warning" v-if="!$v.form.userName.alpha">Ad Soyad şeklinde giriniz.</p>
          <p class="form-warning" v-if="!$v.form.userName.nameSurnameLength">En az 2 karakter girmelisiniz</p>
          <p class="form-warning" v-if="!$v.form.userName.minLength">En az 2 karakter girmelisiniz</p>
          <p class="form-warning" v-if="!$v.form.userName.required">Bu alan zorunludur.</p>
        </div>

        <div class="row">
          <div class="form-group col-12 col-md-6">
            <label for="userMail">E-Posta</label>
            <input 
              v-model="form.userMail" 
              v-on:keydown='key'
              v-bind:class="{error: $v.form.userMail.$error, valid: $v.form.userMail.$dirty && !$v.form.userMail.$invalid}"
              inputmode="email" type="email" class="form-control">
            <p class="form-warning" v-if="!$v.form.userMail.email">Yanlış mail formatı</p>
            <p class="form-warning" v-if="!$v.form.userMail.required">Bu alan zorunludur.</p>
          </div>
          
          <div class="form-group col-12 col-md-6">
            <label for="userPhone">Telefon Numarası</label>
            <the-mask
              name="phone" id="frmPhoneNumA" autocomplete="tel"
              mask="0(###) ### ## ##"
              placeholder="0(---) --- -- --"
              v-model="form.userPhone" 
              v-bind:class="{error: $v.form.userPhone.$error, valid: $v.form.userPhone.$dirty && !$v.form.userPhone.$invalid}"
              type="tel" class="form-control" />
            <p class="form-warning" v-if="!$v.form.userPhone.required">Bu alan zorunludur.</p>
            <p class="form-warning" v-if="!$v.form.userPhone.minLength">Yanlış telefon formatı</p>
            <!-- <input 
              v-model="form.userPhone" 
              v-on:input="$v.form.userPhone.$touch"
              v-bind:class="{error: $v.form.userPhone.$error, valid: $v.form.userPhone.$dirty && !$v.form.userPhone.$invalid}"
              type="text" class="form-control">
            <p class="form-warning" v-if="$v.form.userPhone.$invalid">Bu alan zorunludur.</p> -->
          </div>
        </div>

        <!-- <div class="form-group" v-if="this.$store.state.formValue.donation_status == 'duzenli' ">
          <label for="userTc">TC Kimlik No</label>
          <the-mask
            mask="###########"
            v-model="form.userTc" 
            v-bind:class="{error: $v.form.userTc.$error, valid: $v.form.userTc.$dirty && !$v.form.userTc.$invalid}"
            type="tel" class="form-control" />
          <p class="form-warning" v-if="!$v.form.userTc.required">Bu alan zorunludur.</p>
          <p class="form-warning" v-if="!$v.form.userTc.minLength">Yanlış format</p>
        </div> -->
        
        <!-- <div class="form-group">
          <label for="userAddress">Adres</label>
          <textarea 
            v-model="form.userAddress" 
            v-bind:class="{error: $v.form.userAddress.$error, valid: $v.form.userAddress.$dirty && !$v.form.userAddress.$invalid}"
            type="text" class="form-control"></textarea>
          <p class="form-warning" v-if="!$v.form.userAddress.required">Bu alan zorunludur.</p>
          <p class="form-warning" v-if="!$v.form.userAddress.minLength">En az 10 karakter giriniz.</p>
        </div> -->
           <div class="form-group d-none">
          <label for="userAddress">Adres</label>
          <textarea 
            v-model="form.userAddress" 
           
            type="text" class="form-control"></textarea>
          
        </div>

        <label class="custom-checkbox"> <a href="https://bilimseferberligi.org/kvkk" target="_blank">Kişisel verilerin korunması ve işlenmesine ilişkin politikayı </a>
         okudum, onaylıyorum.
          <input type="checkbox"
          v-model="form.terms_accepted"
          v-bind:class="{error: $v.form.terms_accepted.$error, valid: $v.form.terms_accepted.$dirty && !$v.form.terms_accepted.$invalid}"
          >
          <span class="checkmark"></span>
        </label>
        <ButtonRounded class="float-right" 
        Text="Devam Et"
        :isRouting="false"
        :disabled="disabled"
        />
      </form>
    </div>

  </div>
</template>

<script>
import ButtonRounded from "@/components/ButtonRounded";
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

import {required, email, minLength, helpers, requiredIf} from 'vuelidate/lib/validators';
const nameSurnameLength = (value) => {
  let isValid = true;
  value.split(" ").forEach(e => {
    if(!/^\s*$/.test(e)){
      if(e.length < 2){
        isValid = false;
      }
    }
    
    
  });
  return isValid;
}


const checkTcNum = function(value) {
  if(value.length > 0){
    value = value.toString();
    var isEleven = /^[0-9]{11}$/.test(value);
    var totalX = 0;
    for (var i = 0; i < 10; i++) {
      totalX += Number(value.substr(i, 1));
    }
    var isRuleX = totalX % 10 == value.substr(10,1);
    var totalY1 = 0;
    var totalY2 = 0;
    for (var i = 0; i < 10; i+=2) {
      totalY1 += Number(value.substr(i, 1));
    }
    for (var i = 1; i < 10; i+=2) {
      totalY2 += Number(value.substr(i, 1));
    }
    var isRuleY = ((totalY1 * 7) - totalY2) % 10 == value.substr(9,0);
    return isEleven && isRuleX && isRuleY;
  }
  return true;
};


export default {
  name: "Bilgiler",
  components: {
    ButtonRounded, Loading
  },
  props: [ "formValue" ],
  data(){
    return{
      disabled: false,
      isLoading: false,
      fullPage: false,
      form: {
        userName: '',
        userMail: '',
        userPhone: '',
        userTc: '',
        userAddress: '',
        terms_accepted: false,
      },
    }
  },
  validations: {
    form:{
      userName: {
        required,
        nameSurnameLength,
        minLength: minLength(2),
        alpha: helpers.regex('alpha', /^[a-z\u00C0-\u02AB'´`]+\.?\s([a-z\u00C0-\u02AB'´`]+\.?\s?)+$/i),
      },
      userMail: {
        required,
        email: email
      },
      userPhone: {
        required,
        minLength: minLength(10),
      },
      userTc: {
        // required: requiredIf(function() {
        //   return this.$store.state.formValue.donation_status == 'duzenli' ? true : false;
        // }),
        // checkTcNum,
        // minLength: minLength(11),
      },
      userAddress: {
        // required,
        // minLength: minLength(11),
      },
      terms_accepted: {
        checked: value => value === true
      }
    }
  },
  methods: {
    doAjax() {
        this.isLoading = true
        setTimeout(() => {
          this.isLoading = false
        },1500)
    },
    removeSpace: function(event){
      this.form.userName = this.form.userName.replace( /\s\s+/g, ' ' ).trimStart();
    },
    key: function(event){
      if(event.keyCode === 32){
        event.preventDefault();
      }
    },
    getinfoCreate(data){
      // console.log("data",typeof(data.userName.$model.split(" ")));
      let params = {}
      let nameSurname = data.userName.$model.replace(/\s+$/, '');
      params["name"] = nameSurname.split(' ').slice(0, -1).join(' ');
      params["surname"] = nameSurname.split(' ').slice(-1).join('');
      params["email"] = data.userMail.$model;
      params["phone"] = data.userPhone.$model;
      params["identity_number"] = data.userTc.$model;
      params["adress"] = data.userAddress.$model;
      params["amount"] = this.$store.state.formValue.amount;
      params["donation_id"] = this.$store.state.formValue.donation_id;
      params["price"] = this.$store.state.formValue.price;
      params["donation_type"] = this.$store.state.formValue.donation_type;
      params["selected_type"] = this.$store.state.formValue.selected_type;
      params["donation_status"] = this.$store.state.formValue.donation_status;

      this.disabled = true;
      this.doAjax();

      this.$api.infoCreate(params).then(response => {
        // console.log(response);
        if(response.success){
          this.disabled = false;
          this.$store.commit('_conversation_id', response.info.conversation_id);
          this.$store.commit('_userInfo', response.info);

          var elem = document.querySelector(".modal-wrapper");
          elem.scrollIntoView({behavior: "smooth"});
          this.$emit('clicked', '1');
        gtag('event', 'Bağış Kişi Bilgileri', {
        'event_category': 'Bağış',
        'event_label': 'Bağış Kişi Bilgileri',
        'value': ''
      });

        }else{
          this.disabled = false;
          // Popup Mesaj gelecek
        }
      })

    },
    submitForm(){
      this.$v.$touch();
      if(!this.$v.form.$invalid){
        this.getinfoCreate(this.$v.form);
        
      }else{
        // console.log("not valid");
        // console.log(this.$v.form);
      }
    }
  }
}
</script>

<style lang="scss">
.info-bg{
  position: absolute;
  bottom: 0;
  right: -3rem;
  height: 75%;
}

@media (max-width: 768px){
  .sides{
    form{
      padding-bottom: 4rem;
    }
  }
}
</style>
<template>
  <footer>
    <div class="container">
      <div class="newsletter">
        <div class="d-flex align-items-center justify-content-end">
          <img src="/svg/newsletter.svg">
        </div>
        <div class="d-flex flex-column flex-md-row align-items-center justify-content-between text-center text-md-left mb-4">
          <h3>Gelişmelerden <br>
              Haberdar Olmak <br>
              İster misin?</h3>

          <SubscribeButton />
        </div>

        <!-- <p class="text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam facilisis ornare arcu eget iaculis. <br>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> -->
      </div>

      <div class="footer-nav d-flex flex-column flex-md-row align-items-start justify-content-between">
        <img class="logo" src="/svg/bs-logo-white.svg" alt="Bilim Seferberliği">

        <nav class="d-flex justify-content-between">
          <div class="d-flex flex-column">
            <p>Hakkımızda</p>
            <a href="https://www.bilimseferberligi.org/hakkimizda" target="_blank">Bilim Seferberliği</a>
            <a href="https://www.bilimseferberligi.org/hakkimizda#bilimsetleri" target="_blank">Bilim Setleri</a>
            <a href="https://www.bilimseferberligi.org/hakkimizda#ygahakkinda" target="_blank">YGA Hakkında?</a>
            <a href="https://www.bilimseferberligi.org/sikca-sorulan-sorular" target="_blank">Sıkça Sorulan Sorular</a>
            <a href="https://www.bilimseferberligi.org/iletisim" target="_blank">İletişim</a>
          </div>
          
          <div class="d-flex flex-column">
            <p>Destekçilerimiz</p>
            <a href="https://www.bilimseferberligi.org/destekcilerimiz" target="_blank">Bireysel Bağışçılar</a>
            <a href="https://www.bilimseferberligi.org/destekcilerimiz#kurumsalbagis" target="_blank">Kurumsal Bağışçılar</a>
          </div>
          
          <div class="d-flex flex-column">
            <p>Hikayelerimiz</p>
            <a href="https://www.bilimseferberligi.org/hikayelerimiz" target="_blank">Öğrenci Hikayeleri</a>
            <a href="https://www.bilimseferberligi.org/hikayelerimiz#ogrencihikayeleri" target="_blank">Öğretmen Hikayeleri</a>
            <a href="https://www.bilimseferberligi.org/hikayelerimiz#bilimsenlikleri" target="_blank">Bilim Şenlikleri</a>
          </div>
        </nav>

      </div>

      <div class="d-none d-md-flex align-items-center justify-content-between">
        <a href="https://meb.gov.tr/" target="_blank">
          <img src="/svg/meb-logo-disi.svg">
        </a>
        <div class="social-button">
          <a href="https://twitter.com/bseferberligi" target="_blank"><span class="svg-icon icons-twitter"></span></a>
          <a href="https://www.linkedin.com/company/young-guru-academy-yga/" target="_blank"><span class="svg-icon icons-linkedin"></span></a>
          <a href="https://www.instagram.com/bilimseferberligi/" target="_blank"><span class="svg-icon icons-instagram"></span></a>
          <a href="https://www.facebook.com/bilimseferberligi/" target="_blank"><span class="svg-icon icons-facebook"></span></a>
        </div>
      </div>

      <div class="copyright d-flex flex-column flex-md-row align-items-center justify-content-between">
        <div class="d-flex flex-column flex-md-row align-items-center">
          <a href="https://www.yga.org.tr/" target="_blank" class="d-flex align-items-center">
            <img src="/img/yga-logo.png" alt="YGA Logo" class="mr-2">
            <p>Bilim Seferberliği bir <span>YGA</span> projesidir.</p>
          </a>
          <a href="https://meb.gov.tr/" target="_blank">
            <img src="/svg/meb-logo-disi.svg" class="d-block d-md-none">
          </a>
        </div>
        
        <div class="kvkk">
          <a href="https://www.bilimseferberligi.org/Bilim_Seferberligi_kisisel_verilerin_korunmasi_ve_islenmesi_politikasi2.pdf" target="_blank">Kişisel Verileri Koruma</a>
          <a href="https://www.bilimseferberligi.org/Bilim_seferberligi_aydinlatma_ve_riza_metni.pdf" target="_blank">Kişisel Veri Aydınlatma ve Rıza Metni</a>
        </div>
        <div class="social-button d-block d-md-none">
          <a href="https://twitter.com/bseferberligi" target="_blank"><span class="svg-icon icons-twitter"></span></a>
          <a href="https://www.linkedin.com/company/young-guru-academy-yga/" target="_blank"><span class="svg-icon icons-linkedin"></span></a>
          <a href="https://www.instagram.com/bilimseferberligi/" target="_blank"><span class="svg-icon icons-instagram"></span></a>
          <a href="https://www.facebook.com/bilimseferberligi/" target="_blank"><span class="svg-icon icons-facebook"></span></a>
        </div>
      </div>

    </div>
  </footer>
</template>

<script>
import SubscribeButton from "@/components/SubscribeButton";

export default {
  name: "FooterLanding",
  components: {
    SubscribeButton
  }
}
</script>

<style lang="scss">
footer{
  background: var(--purpleLight);
  color: #fff;
  padding: 3rem 0;

  .newsletter{
    width: 100%;
    background: var(--purpleDark);
    border-radius: 30px;
    padding: 2rem 5rem;
    display: inline-block;
    margin-top: -6rem;
    img{
      margin-top: -100px;
    }
  }

  .footer-nav{
    padding: 2rem 0;
    nav{
      width: 50%;
      p{
        font-weight: 500;
        margin-bottom: 1rem;
      }
      a{
        font-weight: 300;
        font-size: .8rem;
        margin-bottom: .5rem;
        opacity: .5;
        transition: var(--fast);
        &:hover{
          opacity: 1;
        }
      }
    }

    .logo{
      width: 170px;
      aspect-ratio: 170 / 46;
    }
  }

  .social-button{
    a{
      padding-left: 1rem;
      display: inline-block;
      transition: var(--fast);
      &:hover{
        opacity: .7;
      }
      .svg-icon{
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }

  .copyright{
    padding: .9rem 0;
    margin-top: 1rem;
    border-top: 1px solid #403a87;
    border-bottom: 1px solid #403a87;
    line-height: 1;
    p, a{
      font-size: .8rem;
      span{
        text-decoration: underline;
      }
    }
    .kvkk{
      a{
        padding-left: 1rem;
        opacity: .5;
        transition: var(--fast);
        &:hover{
          opacity: 1;
        }
        &:first-child{
          border-right: 1px solid #403a87;
          padding-right: 1rem;
        }
      }
    }
  }

  @media (max-width: 567px){
    .newsletter{
      padding: 2rem;
    }

    .footer-nav{
      nav{
        width: 100%;
        margin-top: 1.5rem;
      }
    }

    .copyright{
      padding: 2rem 0;
      
      p{
        margin: 1rem 0;
      }
      .kvkk a{
        display: block;
        text-align: center;
        margin: 1rem 0;
      }
    } 
  }
}
</style>